import * as React from "react"

import Layout from "../../components/layout"
import Seo from "../../components/seo"
import AbstractObsession from "../../components/ArtStyle/AbstractObsession"

const AbstractObsessionPage = () => (
  <Layout>
    <Seo title="Abstract Obsession" />
    <AbstractObsession />
  </Layout>
)

export default AbstractObsessionPage
