import Image1 from './images/full/dazzling_dance.png'
import Image2 from './images/full/lasting_memory.png'
import Image3 from './images/full/spark.png'
import Image4 from './images/full/magnetic_love.png'
import Image5 from './images/full/first_impression.png'
import Image6 from './images/full/playful_mind.png'
import Image7 from './images/full/sphere.png'
import Image8 from './images/full/sandy_river.png'
import Image9 from './images/full/fancy_vortex.png'
import Image10 from './images/full/sea_rainbow.png'
import Image11 from './images/full/eyes_of_the_sun_green.png'
import Image12 from './images/full/emotional_story.png'
import Image13 from './images/full/emotive_dreams.png'
import Image14 from './images/full/the_energy_of_joy_1.png'
import Image15 from './images/full/the_energy_of_joy_2.png'
import Image16 from './images/full/the_energy_of_joy_3.png'
import Image17 from './images/full/purple_spark.png'
import Image18 from './images/full/illusion.png'

export default {
  collection: [
    {
      index: 0,
      name: 'Dazzling Dance',
      source: Image1,
    },
    {
      index: 1,
      name: 'Lasting Memory',
      source: Image2,
    },
    {
      index: 2,
      name: 'Emotive Dreams',
      source: Image3,
    },
    {
      index: 3,
      name: 'Fancy Vortex',
      source: Image4,
    },
    {
      index: 4,
      name: 'First Impression',
      source: Image5,
    },
    {
      index: 5,
      name: 'Playful Mind',
      source: Image6,
    },
    {
      index: 6,
      name: 'Sphere',
      source: Image7,
    },
    {
      index: 7,
      name: 'Sandy River',
      source: Image8,
    },
    {
      index: 8,
      name: 'Fancy Vortex',
      source: Image9,
    },
    {
      index: 9,
      name: 'Sea Rainbow',
      source: Image10,
    },
    {
      index: 10,
      name: 'Eyes Of The Sun',
      source: Image11,
    },
    {
      index: 11,
      name: 'Emotional Story',
      source: Image12,
    },
    {
      index: 12,
      name: 'Emotive Dreams',
      source: Image13,
    },
    {
      index: 13,
      name: 'The Energy of Joy 1',
      source: Image14,
    },
    {
      index: 14,
      name: 'The Energy of Joy 2',
      source: Image15,
    },
    {
      index: 15,
      name: 'The Energy of Joy 3',
      source: Image16,
    },
    {
      index: 16,
      name: 'Purple Spark',
      source: Image17,
    },
    {
      index: 17,
      name: 'Illusion',
      source: Image18,
    }
  ],
};